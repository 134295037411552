import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppContract } from '@core/models/contract.model';
import { EnvironmentService } from '@core/services/environment.service';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContractService extends EntityCollectionServiceBase<AppContract> {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly environmentService: EnvironmentService,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    super('Contract', serviceElementsFactory);
  }

  /**
   * Fetches the customer contract associated with the provided kerberos account ID.
   *
   * @param {string} kerberosAccountId - The unique identifier for the user's Kerberos account.
   * @return {Observable<AppContract>} An observable that emits the customer contract data.
   */
  public getCustomerContract(kerberosAccountId: string): Observable<AppContract> {
    return this.httpClient
      .get<AppContract>(`${this.environmentService.baseAdpHost}/contract/${kerberosAccountId}`, {
        withCredentials: true,
      })
      .pipe(first());
  }

  /**
   * Sends a request to fetch contract information based on the provided email and account name.
   *
   * @param {string} email - The email address associated with the contract.
   * @param {string} accountName - The account name associated with the contract.
   * @return {Observable<boolean>} An observable that emits a boolean indicating the success or failure of the request.
   */
  public requestContractInformation(email: string, accountName: string): Observable<boolean> {
    return this.httpClient
      .post<boolean>(
        `${this.environmentService.baseAdpHost}/contract/contract-information`,
        { email, accountName },
        { withCredentials: true },
      )
      .pipe(first());
  }
}
